/* .popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 510px;
  height: 424px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.popup.large {
  width: 510px;
  height: 424px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin-top: 0;
}

.popup-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.popup-header .close-button:hover .close-icon {
  color: #fff;
  background-color: #f00;
}

.popup-header .close-button:focus {
  outline: none;
}

.popup-header .close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eee;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
}

.popup-body {
  margin-top: 20px;
}



.popup p {
  margin-bottom: 0;
}

.popup button {
  background-color: #f00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #fff;
  color: #f00;
  border: 2px solid #f00;
}

.popup button:focus {
  outline: none;
}

.popup a {
  text-decoration: none;
} */



/*New Code*/

/* 
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.popup.large {
  max-width: 600px;
}

.popup h2 {
  margin-top: 0;
}

.popup p {
  margin-bottom: 0;
}

.popup .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup .popup-header h2 {
  font-size: 24px;
}

.popup .popup-header .close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.popup .popup-header .close-icon {
  font-size: 20px;
  font-weight: bold;
  color: #888;
}

.popup .popup-header .close-icon:hover {
  color: #000;
}

.popup .popup-body {
  position: relative;
  padding-bottom: 60px;
}

.popup .popup-body p {
  margin-bottom: 20px;
}

.popup .popup-body .apply-now {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.2s ease;
}

.popup .popup-body .apply-now:hover {
  background-color: #0069d9;
}

.popup .minimized {
  position: fixed;
  bottom: 20px;
  right: 20px;
} */


/*New responsive code*/
.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 510px;
  height: 424px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  }
  
  .popup.large {
  width: 510px;
  height: 424px;
  }
  
  .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .popup-header h2 {
  margin-top: 0;
  font-size: 24px;
  }
  
  .popup-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  }
  
  .popup-header .close-button:hover .close-icon {
  color: #fff;
  background-color: #f00;
  }
  
  .popup-header .close-button:focus {
  outline: none;
  }
  
  .popup-header .close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eee;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  }
  
  .popup-body {
  margin-top: 20px;
  }
  
  .popup p {
  margin-bottom: 0;
  font-size: 16px;
  }
  
  .popup button {
  background-color: #f00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  }
  
  .popup button:hover {
  background-color: #fff;
  color: #f00;
  border: 2px solid #f00;
  }
  
  .popup button:focus {
  outline: none;
  }
  
  .popup a {
  text-decoration: none;
  }
  
  @media screen and (max-width: 768px) {
  .popup {
  width: 90%;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  }
  
  .popup-header h2 {
  font-size: 20px;
  }
  
  .popup button {
  font-size: 14px;
  padding: 8px 16px;
  }
  }
  
  @media screen and (max-width: 480px) {
  .popup {
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 0;
  padding: 10px;
  }
  
  .popup.large {
  width: 100%;
  height: auto;
  }
  
  .popup-header {
  flex-direction: column;
  align-items: flex-start;
  }
  
  .popup-header h2 {
  margin-top: 10px;
  font-size: 18px;
  }
  
  .popup-header .close-icon {
  margin-left: auto;
  margin-right: 10px;
  }
  
  .popup p {
  font-size: 14px;
  }
  
  .popup button {
  font-size: 14px;
  padding: 8px 16px;
  }
  }